interface BudgetRange {
  min: number;
  max: number;
  label: string;
}

const budgetRanges: BudgetRange[] = [
  { min: 0, max: 1000, label: '- 1000 €' },
  { min: 1000, max: 2000, label: '1000 - 2000 €' },
  { min: 2000, max: 3000, label: '2000 - 3000 €' },
  { min: 3000, max: 4000, label: '3000 - 4000 €' },
  { min: 4000, max: 5000, label: '4000 - 5000 €' },
  { min: 5000, max: 6000, label: '5000 - 6000 €' },
  { min: 6000, max: Infinity, label: '+ 6000 €' }
];

export default function getBudgetRanges({
  minPrice,
  maxPrice
}: {
  minPrice: number;
  maxPrice: number;
}): string[] {
  const result: string[] = [];

  for (const range of budgetRanges) {
    if (minPrice <= range.max && maxPrice >= range.min) {
      result.push(range.label);
    }
  }

  return result;
}
