interface DurationRange {
  min: number;
  max: number;
  label: string;
  value: string;
}

const durationRanges: DurationRange[] = [
  { min: 0, max: 7, label: '- de 7 jours', value: '-7' },
  { min: 7, max: 13, label: '7 à 13 jours', value: '7-13' },
  { min: 14, max: 20, label: '14 à 20 jours', value: '14-20' },
  { min: 21, max: Infinity, label: '21 jours et +', value: '21+' }
];

export default function getDurationRanges({
  minDuration,
  maxDuration
}: {
  minDuration: number;
  maxDuration: number;
}): { label: string; value: string }[] {
  const result: { label: string; value: string }[] = [];

  for (const range of durationRanges) {
    if (minDuration <= range.max && maxDuration >= range.min) {
      result.push({ label: range.label, value: range.value });
    }
  }

  return result;
}
