<template>
  <div
    v-if="isOpen"
    class="filters-modal-container"
    @click.self="
      emit('toggle-modal', false);
      emit('set-filters', selected);
    "
  >
    <div class="filters-modal">
      <div class="top-banner">
        <button class="reset" @click="emit('reset-filters')">
          <Icon name="reinitialize" />
        </button>
        <p>Filtres ({{ filtersCount }})</p>
        <button
          class="close-button"
          @click="
            emit('toggle-modal', false);
            emit('set-filters', selected);
          "
        >
          <Icon name="exit" />
        </button>
      </div>
      <div class="content-container">
        <div
          v-if="averageBudget && filtersToDisplay.includes(MODAL_FILTERS.BUDGET)"
          class="filters-group"
        >
          <div class="filters-labels">
            <div class="filter-main-label">Budget moyen par personne</div>
            <div class="filter-sub-label">
              Budget moyen pour {{ countryName }} : {{ averageBudget.min }} -
              {{ averageBudget.max }}
            </div>
          </div>
          <div v-if="priceRanges.length > 0" class="filters-boxes">
            <FiltersCheckbox
              v-for="budget in priceRanges"
              :key="budget"
              :is-checked-attr="isSelected('budgets', budget)"
              :text="budget"
              identifier="budgets"
              :value="budget"
              @on-select="handleOnSelect"
            />
          </div>
        </div>
        <hr v-if="filtersToDisplay.includes(MODAL_FILTERS.BUDGET)" class="custom-separator" />
        <div
          v-if="filtersToDisplay.includes(MODAL_FILTERS.DURATION) && durationRanges.length > 0"
          class="filters-group"
        >
          <div class="filters-labels">
            <div class="filter-main-label">Durée</div>
          </div>
          <div class="filters-boxes">
            <FiltersCheckbox
              v-for="durationRange of durationRanges"
              :key="durationRange.label"
              :is-checked-attr="isSelected('durations', durationRange.value)"
              :text="durationRange.label"
              identifier="durations"
              :value="durationRange.value"
              @on-select="handleOnSelect"
            />
          </div>
        </div>
        <hr v-if="filtersToDisplay.includes(MODAL_FILTERS.DURATION)" class="custom-separator" />
        <div
          v-if="
            filtersToDisplay.includes(MODAL_FILTERS.TYPE_CATEGORY) &&
            (fetchedFilterData.typeCategories?.length ?? 0) > 0
          "
          class="filters-group"
        >
          <div class="filters-labels">
            <div class="filter-main-label">Style de voyage</div>
          </div>
          <div class="filters-boxes">
            <FiltersCheckbox
              v-for="item in fetchedFilterData.typeCategories"
              :key="item.id"
              :is-checked-attr="isSelected(item.identifier, item.value)"
              :text="item.name"
              :identifier="item.identifier"
              :value="item.value"
              :strategy="item.strategy"
              @on-select="handleOnSelect"
            />
          </div>
        </div>
        <hr
          v-if="filtersToDisplay.includes(MODAL_FILTERS.TYPE_CATEGORY)"
          class="custom-separator"
        />
        <div v-if="filtersToDisplay.includes(MODAL_FILTERS.WISH_CATEGORY)" class="filters-group">
          <div class="filters-labels">
            <div class="filter-main-label">Selon vos envies</div>
          </div>
          <div class="filters-boxes categories card-box grid-wish">
            <FiltersModalCategoryCard
              v-for="item in fetchedFilterData.wishCategories"
              :key="item.id"
              :is-checked-attr="isSelected(item.identifier, item.value)"
              class="category-card"
              :identifier="item.identifier"
              :text="item.name"
              :value="item.value"
              :icon-name="item.icon ?? ''"
              :strategy="item.strategy"
              @on-select="handleOnSelect"
            />
          </div>
        </div>
        <hr
          v-if="filtersToDisplay.includes(MODAL_FILTERS.WISH_CATEGORY)"
          class="custom-separator"
        />
        <div v-if="filtersToDisplay.includes(MODAL_FILTERS.STOPOVERTOWN)" class="filters-group">
          <div class="filters-labels">
            <div class="filter-main-label">Villes / Régions</div>
          </div>
          <div
            v-if="
              fetchedFilterData.stopoverTownCategories &&
              fetchedFilterData.stopoverTownCategories?.length > 8
            "
            class="filters-boxes-expandable"
          >
            <div class="expandable-list">
              <FiltersCheckbox
                v-for="location in isLocationsExpanded
                  ? fetchedFilterData.stopoverTownCategories
                  : fetchedFilterData.stopoverTownCategories.slice(0, 8)"
                :key="location.name"
                :is-checked-attr="isSelected(location.identifier, location.value)"
                :text="location.name"
                :identifier="location.identifier"
                :value="location.value"
                :strategy="location.strategy"
                @on-select="handleOnSelect"
              />
              <span v-if="!isLocationsExpanded" class="see-more" @click="isLocationsExpanded = true"
                >Afficher plus</span
              >
              <span v-else class="see-more" @click="isLocationsExpanded = false"
                >Afficher moins</span
              >
            </div>
          </div>
        </div>
        <hr v-if="filtersToDisplay.includes(MODAL_FILTERS.STOPOVERTOWN)" class="custom-separator" />
        <div v-if="filtersToDisplay.includes(MODAL_FILTERS.PERIOD)" class="filters-group">
          <div class="filters-labels">
            <div class="filter-main-label">Période de départ</div>
          </div>
          <div class="filters-boxes categories">
            <FiltersCheckbox
              v-for="item in fetchedFilterData.seasons"
              :key="item.id"
              :is-checked-attr="isSelected('seasons', item.value)"
              class="category-card"
              :identifier="item.id"
              :text="item.name"
              :value="item.value"
              :icon-name="item.icon ?? ''"
              :strategy="item.strategy"
              @on-select="handleOnSelect('seasons', item.value, item.name, item.strategy)"
            />
          </div>
        </div>
        <div v-if="filtersToDisplay.includes(MODAL_FILTERS.MONTHS)" class="filters-group">
          <div class="filters-labels">
            <div class="filter-main-label">Date</div>
          </div>
          <div class="filters-boxes">
            <FiltersCheckbox
              v-for="month in fetchedFilterData.months"
              :key="month.name"
              :is-checked-attr="isSelected(month.identifier, month.value)"
              :text="month.name"
              :identifier="month.identifier"
              :value="month.value"
              @on-select="handleOnSelect"
            />
          </div>
        </div>
        <hr v-if="filtersToDisplay.includes(MODAL_FILTERS.MONTHS)" class="custom-separator" />
        <div
          v-if="
            filtersToDisplay.includes(MODAL_FILTERS.WP_CATEGORY_EVENT) ||
            filtersToDisplay.includes(MODAL_FILTERS.WP_CATEGORY_ARTICLE)
          "
          class="filters-group"
        >
          <div class="filters-labels">
            <div class="filter-main-label">
              {{
                filtersToDisplay.includes(MODAL_FILTERS.WP_CATEGORY_ARTICLE)
                  ? "Type d'articles"
                  : "Type d'évènements"
              }}
            </div>
          </div>
          <div
            v-if="fetchedFilterData.articleTags && fetchedFilterData.articleTags?.length > 8"
            class="filters-boxes-expandable"
          >
            <div class="expandable-list">
              <FiltersCheckbox
                v-for="category in isEventsExpanded
                  ? fetchedFilterData.articleTags
                  : fetchedFilterData.articleTags?.slice(0, 8)"
                :key="category.name"
                :is-checked-attr="isSelected(category.identifier, category.value)"
                :text="category.name"
                :identifier="category.identifier"
                :value="category.value"
                @on-select="handleOnSelect"
              />
              <span v-if="!isEventsExpanded" class="see-more" @click="isEventsExpanded = true"
                >Afficher plus</span
              >
              <span v-else class="see-more" @click="isEventsExpanded = false">Afficher moins</span>
            </div>
          </div>
        </div>
        <hr v-if="filtersToDisplay.includes(MODAL_FILTERS.WP_CONTINENT)" class="custom-separator" />
        <div v-if="filtersToDisplay.includes(MODAL_FILTERS.WP_CONTINENT)" class="filters-group">
          <div class="filters-labels">
            <div class="filter-main-label">Destinations</div>
          </div>
          <div class="filters-boxes">
            <FiltersCheckbox
              v-for="continent in fetchedFilterData.wpContinents"
              :key="continent.name"
              :is-checked-attr="isSelected(continent.identifier, continent.value)"
              :text="continent.name"
              :identifier="continent.identifier"
              :value="continent.value"
              :strategy="continent.strategy"
              @on-select="handleOnSelect"
            />
          </div>
        </div>
      </div>
      <div class="lower-banner-cta">
        <div
          role="button"
          class="reset-filters"
          @click="
            emit('reset-filters');
            emit('toggle-modal', false);
          "
        >
          <Icon width="20px" height="20px" name="reinitialize" stroke="var(--color-green)" />
          <span class="text"> Réinitialiser les filtres </span>
        </div>
        <button
          class="cta-apply-filters"
          @click="
            emit('toggle-modal', false);
            emit('set-filters', selected);
          "
        >
          Appliquer les filtres
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import MODAL_FILTERS from '@/lib/types/enums/modalFilters.enum';
import { Filter, FilterDefinition, FiltersObject } from '@/lib/types/filters';
import { MinMaxRange } from '@/lib/types/models/common';
import { CountryBudget } from '@/lib/types/models/country';

const props = defineProps<{
  isOpen: boolean;
  countryName: string;
  averageBudget?: CountryBudget;
  selectedFilter: FiltersObject;
  fetchedFilterData: {
    stopoverTownCategories?: FilterDefinition<'stopoverTownCategories'>[];
    seasons?: FilterDefinition<'seasons'>[];
    typeCategories?: FilterDefinition<'typeCategories'>[];
    wishCategories?: FilterDefinition<'wishCategories'>[];
    months?: FilterDefinition<'months'>[];
    articleTags?: FilterDefinition<'articleTags'>[];
    wpContinents?: FilterDefinition<'wpContinents'>[];
  };
  filtersToDisplay: MODAL_FILTERS[];
  minMaxPrice?: MinMaxRange;
  minMaxDays?: MinMaxRange;
}>();

const emit = defineEmits<{
  (event: 'toggle-modal', toggled: boolean): void;
  (event: 'set-filters', filters: FiltersObject): void;
  (event: 'reset-filters'): void;
}>();

const selected = ref(props.selectedFilter);

const filtersCount = computed(() =>
  Object.values(selected.value).reduce(
    (acc, curr) =>
      acc +
      (Array.isArray(curr) ? curr.filter(curr => curr.isDisabled !== true).length : curr ? 1 : 0),
    0
  )
);

const isLocationsExpanded = ref(false);

const isEventsExpanded = ref(false);

const priceRanges = props.minMaxPrice
  ? getBudgetRanges({
      minPrice: props.minMaxPrice.min,
      maxPrice: props.minMaxPrice.max
    })
  : [];

const durationRanges = props.minMaxDays
  ? getDurationRanges({
      minDuration: props.minMaxDays.min,
      maxDuration: props.minMaxDays.max
    })
  : [];

watchEffect(() => {
  selected.value = props.selectedFilter;
});

function handleOnSelect(
  filterName: string,
  value: string,
  text: string,
  strategy?: Filter['strategy']
): void {
  // TODO: Is it working? + Repetition

  let filterValue = selected.value[filterName];

  // User selected a single-toggle filter
  if (!filterValue) {
    filterValue = { identifier: filterName, text, value, strategy };
  } else if (Array.isArray(filterValue)) {
    // User un-selected a multi-toggle filter
    if (filterValue.some(i => i.value === value)) {
      filterValue = filterValue.filter(f => f.value !== value);
    }
    // User selected a multi-toggle filter
    else {
      filterValue = [...filterValue, { identifier: filterName, text, value, strategy }];
    }
  }
  // User un-selected a single-toggle filter
  else if (filterValue.value === value) {
    filterValue = null;
  }

  selected.value[filterName] = filterValue;
}

function isSelected(key: string, value: string): boolean {
  const filterValue = selected.value[key];

  return Array.isArray(filterValue)
    ? filterValue.some(i => i.value === value)
    : filterValue?.value === value;
}

function onEscapeKeyDown(e: KeyboardEvent): void {
  if (e.key === 'Escape') {
    emit('toggle-modal', false);
    emit('set-filters', selected.value);
  }
}

onMounted(() => {
  document.addEventListener('keydown', onEscapeKeyDown);
});

onBeforeUnmount(() => {
  document.removeEventListener('keydown', onEscapeKeyDown);
});
</script>

<style lang="scss" scoped>
@use '$/border-radius.scss';
@use '$/button.scss';
@use '$/colors.scss';
@use '$/misc.scss';

.filters-modal-container {
  position: fixed;
  z-index: 10000;
  inset: 0;

  overflow: hidden;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  background-color: rgba(0 0 0 / 40%);

  .filters-modal {
    flex-direction: column;

    width: 100%;
    max-width: 675px;
    height: 100dvh;
    max-height: 750px;

    background-color: white;
    border-radius: border-radius.$small;

    .top-banner {
      position: relative;

      align-items: center;
      justify-content: center;

      width: 100%;
      padding: 17.5px 13.5px;

      border-bottom: 1px solid colors.$grey-500;

      .reset,
      .close-button {
        @include button.transparent(small);
        @include button.with-icon();
      }

      p {
        margin-left: 10px;
        font-weight: 600;
      }

      .close-button {
        position: absolute;
        right: 0;
      }
    }
  }

  .content-container {
    $inside-spacing: 32px;

    position: relative;

    overflow-y: scroll;
    flex-direction: column;
    align-items: center;

    padding: 0 $inside-spacing $inside-spacing;

    .filters-group {
      flex-direction: column;
      width: 100%;
      margin: $inside-spacing 0;

      .filters-labels {
        flex-direction: column;

        .filter-main-label {
          font-size: 18px;
          font-weight: 600;
        }

        .filter-sub-label {
          color: colors.$grey-700;
        }
      }

      :deep(label) {
        margin-right: 20px;
      }

      .filters-boxes {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        flex-flow: row wrap;
        gap: 12px;

        width: 100%;
        margin-top: 20px;

        :deep(label) {
          margin-bottom: 5px;
        }

        .see-more {
          @include misc.bold-link();
        }

        &.grid-wish {
          grid-template-columns: repeat(4, 1fr);
        }
      }

      .filters-boxes-expandable {
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        margin-top: 20px;

        .expandable-list {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          row-gap: 20px;
          width: 100%;

          :deep(label) {
            margin-bottom: 5px;
          }

          .see-more {
            @include misc.bold-link();
          }
        }

        .category-card {
          margin: 0 5px 5px 0;
        }
      }
    }
  }

  .lower-banner-cta {
    justify-content: center;
    justify-content: space-between;

    width: 100%;
    padding: 8px 32px;

    background-color: white;
    border-top: solid 2px colors.$gold;
    border-radius: border-radius.$small;

    .reset-filters {
      @include misc.bold-link();

      gap: 5px;
      align-items: center;
    }

    .cta-apply-filters {
      @include button.plain();
    }
  }

  .custom-separator {
    width: 100%;
    height: 0;
    margin: 0;

    border: 0;
    border-bottom: 1px solid #bbb;
  }
}

@media (width <= 768px) {
  .filters-modal-container {
    .lower-banner-cta {
      .cta-apply-filters {
        @include button.plain($size: small);
      }

      .reset-filters {
        .text {
          display: none;
        }
      }
    }

    .filters-modal {
      height: 100vh;
      max-height: none;

      .content-container {
        .filters-group {
          margin: 18px 0;

          .filter-main-label {
            font-size: 16px;
          }

          .filter-sub-label {
            font-size: 14px;
          }

          .filters-boxes-expandable {
            .expandable-list {
              display: flex;
              flex-direction: column;

              :deep(.container) {
                flex-direction: row-reverse;
                justify-content: space-between;
              }
            }

            .category-card {
              margin: 0 5px 5px 0;
            }
          }

          .filters-boxes {
            display: flex;
            flex-direction: column;
            gap: 8px;

            &.categories {
              display: grid;
              grid-template-columns: 1fr 1fr;

              :deep(.card-container) {
                width: 100%;
              }
            }

            :deep(.container) {
              flex-direction: row-reverse;
              justify-content: space-between;
            }

            &.card-box {
              justify-content: center;
            }
          }
        }

        .cta-apply-filters {
          @include button.outlined($size: small, $color-hover: colors.$green);
        }
      }
    }
  }
}
</style>
