<template>
  <div
    class="card-container"
    :class="{ selected: isChecked }"
    @click="
      isChecked = !isChecked;
      emit('on-select', identifier, value, text, strategy);
    "
  >
    <!-- TODO: change icon color management -->
    <Icon
      :class="{ 'card-icon': isChecked }"
      :name="iconName"
      :stroke="isChecked ? 'white' : ''"
      width="22px"
      height="22px"
    />
    <span>{{ text }}</span>
  </div>
</template>

<script lang="ts" setup>
import { Filter } from '@/lib/types/filters';

// TODO: Nearly duplicated with FiltersRadio

defineProps<{
  text: string;
  iconName: string;
  identifier: string;
  value: string;
  strategy?: Filter['strategy'];
}>();

const emit =
  defineEmits<
    (
      event: 'on-select',
      identifier: string,
      value: string,
      text: string,
      strategy?: Filter['strategy']
    ) => void
  >();

// TODO: Vérifier si ça marche (passer en prop)
const attrs = useAttrs();
const isChecked = ref(!!attrs['is-checked-attr']);

watchEffect(() => {
  isChecked.value = !!attrs['is-checked-attr'];
});
</script>

<style lang="scss" scoped>
@use '$/colors.scss';

.card-container {
  cursor: pointer;

  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100px;

  text-align: center;

  border: solid 1px colors.$grey-500;

  transition: 0.2s;

  span {
    margin-top: 12px;
    transition: 0.2s;
  }
}

.selected {
  background-color: colors.$green;
  border: solid 1px colors.$green;

  span {
    color: white;
  }

  .card-icon {
    color: colors.$green;
    transition: 0.2s;
  }
}
</style>
